@import "./variables.scss";
@import "./colors";
@import "./mixins";

.date-calendar {
  border-radius: var(--radius-friendly, 1rem);
  border: 0.5px solid var(--color-line-quiet, #bfbfbf);
  background: var(--color-surface-level-1, #fff);
  box-shadow: var(--shadow-x-floating, 0px) var(--shadow-y-floating, 2px)
    var(--shadow-blur-floating, 8px) 0px
    var(--shadow-color-floating, rgba(51, 51, 51, 0.4));
  display: flex;
  width: 22.0625rem;
  flex-direction: column;
  align-items: center;

  &.MuiPickersCalendarHeader-root {
    &.MuiPickersArrowSwitcher-spacer {
      width: 0px;
    }
  }

  margin: $grid-2x auto $grid-1x;
  @include roundCorner($grid-1x);
  max-width: calc(43.75 * $grid-1x);
  padding: calc($grid-1x/2) calc($grid-1x) $grid-2x;
  width: 92%;
  position: relative;

  &.preference-cal {
    .MuiDateCalendar-root {
      overflow: scroll;

      .MuiDayCalendar-root {
        overflow-y: hidden;

        .MuiPickersSlideTransition-root {
          overflow-x: unset;

          .MuiDayCalendar-monthContainer {
            .MuiDayCalendar-weekContainer {
              button {
                &:not([aria-colindex="1"]) {
                  position: relative;

                  ::before {
                    content: "";
                    position: absolute;
                    border-top: 1px solid $primary-text;
                    top: calc(50% - 1px);
                    left: 50%;
                    width: 57%;
                    transform: translateX(-50%);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // latest calender changes
  .MuiMonthCalendar-root {
    width: 100%;
    border: 1px solid #d9ece6;
    background-color: white;
    border-radius: 8px 8px 0px 0px;

    .MuiPickersMonth-monthButton.Mui-selected {
      background-color: $green900;
      border: 1px solid $green300;
      border-radius: 7px;
      color: $green300;
    }
  }

  .MuiDateCalendar-root {
    overflow: scroll;
    width: 100%;
    height: auto;
    min-height: calc($grid-1x * 33.25);

    .MuiPickersCalendarHeader-root {
      position: relative;
      margin-top: calc($grid-1x/2);

      .MuiPickersCalendarHeader-labelContainer {
        margin: 0 auto;

        .MuiPickersCalendarHeader-label {
          font-family: $SodoSansSemiBold;
          font-size: 1.071rem;
          color: $primary-text;
        }

        button {
          padding: 0;

          svg {
            font-size: 2rem;
            color: $primary-text;
          }
        }
      }

      .MuiPickersArrowSwitcher-root {
        border-radius: var(--radius-minimal, 0.5rem);
        background: var(--color-interactive-tertiary, #fff);

        .MuiIconButton-edgeEnd,
        .MuiIconButton-edgeStart {
          position: absolute;
          top: 0;
          bottom: 0;
          font-size: 2rem;
          color: $primary-text;
          background-color: transparent;
        }

        .MuiIconButton-edgeEnd {
          left: 0;
        }

        .MuiIconButton-edgeStart {
          right: 0;
        }
      }
    }

    .MuiDayCalendar-root {
      .MuiDayCalendar-header {
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;

        span {
          font-size: 1rem;
          color: $primary-text-hover;
          font-family: $SodoSans;
          height: $grid-4x;
        }
      }

      .MuiPickersSlideTransition-root {
        overflow-x: unset;
        min-height: calc($grid-1x * 34);

        .MuiDayCalendar-monthContainer {
          @include roundCorner($grid-1x);
          background-color: $white;
          width: 92%;
          margin: 2px auto;
          padding: calc($grid-1x/2);

          .MuiDayCalendar-weekContainer {
            width: 97%;
            margin: calc($grid-1x/2) auto;
            justify-content: space-between;

            button {
              font-size: 1.071rem;
              color: $primary-text;
              font-family: $SodoSans;

              &[aria-current="date"] {
                border: 0;
              }

              &.Mui-disabled {
                color: $black500;
              }

              &[aria-selected="true"] {
                background-color: $green900;
                border: 1px solid $green300;
                color: $green300;
                z-index: 9999 !important;
                border-radius: ($grid-2x $grid-2x $grid-2x $grid-2x) !important;
              }

              &.strikeDays {
                ::before {
                  content: "";
                  position: absolute;
                  border-top: 1px solid $primary-text;
                  top: calc(50% - 1px);
                  left: 50%;
                  width: 57%;
                  transform: translateX(-50%);
                }
              }

              &.green-days {
                background-color: $green900;
                position: relative;

                &.green-day-0 {
                  border-radius: 50% !important;
                  color: $green300;
                  z-index: 2 !important;

                  &::after {
                    left: 73% !important;
                    top: -3% !important;
                  }

                  &::before {
                    content: "";
                    position: absolute;
                    border: 1px solid $green300;
                    border-radius: 50%;
                    left: -2%;
                    top: -3%;
                    z-index: 3;
                  }
                }

                &.green-day-second-last {
                  &::after {
                    left: 97% !important;
                  }
                }

                &.green-day-last {
                  border: 1px solid $green300;
                  border-radius: 50% !important;
                  color: $green300;
                  z-index: 2 !important;

                  &::after {
                    display: none;
                  }
                }

                &.green-day-before-last {
                  border-radius: 50% !important;
                  color: $green300;
                  z-index: 2 !important;

                  &::after {
                    display: none;
                  }

                  &::before {
                    content: "" !important;
                    position: absolute !important;
                    border-radius: 50% !important;
                    left: -1% !important;
                    top: -3% !important;
                    z-index: 3 !important;
                  }
                }

                &:last-child {
                  &[aria-selected="true"] {
                    &:not([aria-colindex="7"]) {
                      &::after {
                        content: "";
                        width: 0px !important;
                      }
                    }
                  }
                }

                &:not([aria-colindex="7"]) {
                  &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 100%;
                    z-index: 1;
                    background-color: $green900;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // latest calender changes
  .MuiYearCalendar-root {
    width: 100%;
    border: 1px solid #d9ece6;
    background-color: white;
    border-radius: 0px 0px 8px 8px;
    margin: -15px 0px;

    .MuiPickersYear-yearButton.Mui-selected {
      background-color: $green900;
      border: 1px solid $green300;
      border-radius: 7px;
      color: $green300;
    }
  }

  .warning-tip {
    position: static;
    background-color: $red600;
    @include roundCorner($grid-1x);

    position: absolute;
    top: calc($grid-6x + 1px);
    left: calc($grid-2x - 2px);
    padding: $grid-1x;
    width: calc(100% - $grid-8x + 20px);

    img {
      width: calc($grid-2x + 2px);
      margin: 2px 5px 0px 1px;
    }

    span {
      color: $error-text;
      margin-left: calc($grid-1x/4 - 1px);
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-mobile) {
  .date-calendar {
    &.left-cal {
      margin-left: 0;
    }

    &.right-cal,
    &.right-cal-1 {
      margin-right: 0;
    }

    .warning-tip {
      position: static;
      left: calc($grid-2x - 2px);
      width: calc(100% - $grid-9x + 27px);

      img {
        width: calc($grid-3x - 2px);
      }

      &.case-2 {
        img {
          width: calc($grid-5x + 1px);
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .date-calendar {
    .MuiDateCalendar-root {
      overflow: scroll;

      .MuiPickersCalendarHeader-root {
        .MuiPickersCalendarHeader-labelContainer {
          .MuiPickersCalendarHeader-label {
            font-size: 1rem;
          }
        }
      }

      .MuiDayCalendar-root {
        .MuiDayCalendar-header {
          span {
            font-size: 1rem;
          }
        }

        .MuiPickersSlideTransition-root {
          overflow-x: unset;

          .MuiDayCalendar-monthContainer {
            .MuiDayCalendar-weekContainer {
              button {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .warning-tip {
      position: static;
      span {
        font-size: 1rem;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .date-calendar {
    max-width: calc(40.5 * $grid-1x);
    margin-top: calc($grid-3x + 4px);
    padding: calc($grid-1x + 2px) calc($grid-2x - 4px) calc($grid-2x + 4px);

    &.preference-cal {
      .MuiDateCalendar-root {
        overflow: scroll;

        .MuiDayCalendar-root {
          .MuiPickersSlideTransition-root {
            overflow-x: unset;

            .MuiDayCalendar-monthContainer {
              .MuiDayCalendar-weekContainer {
                button {
                  &:not([aria-colindex="1"]) {
                    position: relative;

                    ::before {
                      top: calc(50%);
                      width: 75%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .MuiDateCalendar-root {
      overflow: scroll;
      min-height: calc($grid-1x * 33.25);
      max-height: max-content;

      .MuiPickersCalendarHeader-root {
        .MuiPickersCalendarHeader-labelContainer {
          .MuiPickersCalendarHeader-label {
            font-size: 0.889rem;
          }
        }

        .MuiPickersArrowSwitcher-root {
          .MuiIconButton-edgeEnd,
          .MuiIconButton-edgeStart {
            font-size: 1.3rem;
          }
        }
      }

      .MuiDayCalendar-root {
        .MuiDayCalendar-header {
          span {
            font-size: 0.889rem;
          }
        }

        .MuiPickersSlideTransition-root {
          overflow-x: unset;
          min-height: calc($grid-1x * 34);

          .MuiDayCalendar-monthContainer {
            .MuiDayCalendar-weekContainer {
              margin: $grid-1x auto;

              button {
                padding-top: calc($grid-1x/2);

                &.strikeDays {
                  ::before {
                    top: calc(50%);
                    width: 75%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .warning-tip {
      position: static;
      top: calc($grid-7x - 1px);
      left: calc($grid-3x - 3px);
      width: calc(100% - $grid-9x + 15px);

      img {
        width: calc($grid-3x - 2px);
      }

      span {
        font-size: 0.889rem;
      }

      &.case-2 {
        img {
          width: calc($grid-5x + 1px);
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-large-desktop) {
  .date-calendar {
    max-width: calc(45 * $grid-1x);
  }
}
