@import "./variables.scss";
@import "./colors";
@import "./mixins";

.drawer {
  .date-wrapper {
    border-radius: var(--radius-minimal, 0.5rem);
    border: 0.5px solid var(--color-line-quiet, #bfbfbf);
    background: var(--color-surface-level-1, #fff);
    border: 1px solid $white400;
    padding: calc($grid-2x - 3px) $grid-1x;
    @include roundCorner($grid-1x);
    position: relative;
    color: $primary-text;

    &.half-width {
      width: calc(50% - $grid-2x - 4px);
    }

    .mr-half {
      margin-right: calc($grid-1x / 2);
    }

    svg {
      color: $primary;
      width: calc($grid-2x + 4px);
      height: calc($grid-2x + 4px);
    }

    &.active {
      border: 1px solid $green750;

      &::before {
        content: "";
        position: absolute;
        border: solid $green750;
        top: calc($grid-7x + 1px);
        right: $grid-2x;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-135deg);
        background-color: $white500;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc($grid-8x - 1px);
        right: calc($grid-2x - 1px);
        border: solid $white500;
        border-width: 0 0 1px;
        display: inline-block;
        width: calc($grid-2x - 3px);
        z-index: 2;
      }

      svg {
        color: $secondary-focus;
      }
    }

    &.startDateBorder {
      border: 1px solid $green750;
    }
  }

  .date-header {
    background-color: $white;
    border: 1px solid $green750;
    padding: $grid-1x;
    @include roundCorner($grid-1x);

    &.disable {
      pointer-events: none;
    }
  }

  .date-header-grid {
    padding: 0 $grid-2x 0 $grid-2x;
  }

  button.next-btn {
    width: 100%;
    margin: calc($grid-2x + 4px) 0 calc($grid-1x/2);
    padding: $grid-1x 0;
    font-size: 1.071rem;
    height: 3rem;

    &[disabled] {
      background-color: #666;
    }
  }

  .MuiDivider-vertical {
    height: calc($grid-2x + 4px);
    border-left: 1px solid $primary-text-hover;
    margin: $grid-1x ($grid-1x + 4px) 0;
    border-right: none;

    &.active {
      border-left: 1px solid $primary-text;
    }
  }

  .add-request-section {
    .edit-btn-container {
      padding: 0 $grid-2x 0 $grid-2x;
    }

    .request-content {
      background-color: $white500;
      flex: 1 0 100%;

      &.active {
        border: 1px solid $green400;
      }

      .copy-data {
        display: flex;
        height: 3rem;
        padding: 0.25rem var(--space-2-x, 1rem);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: var(--Minimal, 0.5rem);
        border: 0.5px solid var(--color-line-quiet, #bfbfbf);
        background: var(--color-surface-level-1, #fff);
        flex-grow: 1;
        width: 100%;

        .check-item {
          .MuiCheckbox-root {
            color: $black500;
            padding: calc($grid-1x - 2px) calc($grid-1x) calc($grid-1x - 2px) 0;

            svg {
              font-size: 1.8rem;
            }

            &.Mui-checked {
              color: $secondary;
            }
          }
        }
      }

      .avg-hours {
        font-family: $SodoSansSemiBold;
        color: $primary;
      }

      .day-wrapper {
        background-color: $white;
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        border: 0.5px solid var(--color-line-quiet, #bfbfbf);
        margin-bottom: $grid-1x;
        margin-top: $grid-1x;

        &.active {
          border: 1px solid $green400;
          @include boxShadow(none);

          .day-item {
            border: 1px solid $green400;
            background-color: $green750;
            margin: -1px 0px -1px -1px;
          }
        }

        .day-item {
          width: 3.125rem;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          border-radius: var(--radius-none, 0rem);
          border-right: 0.5px solid var(--color-line-quiet, #bfbfbf);
          background: var(--color-surface-level-1, #fff);
        }

        .day-action {
          width: calc(100% - $grid-7x - 1px);
          padding: calc($grid-2x - 2px) calc($grid-1x/2) $grid-1x;

          .day-hours {
            color: $primary-text-hover;
          }

          .available-hours {
            color: var(--color-ink-leading, #1a1a1a);
            font-family: "SoDo Sans";
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .switch-wrapper {
            margin: calc($grid-1x + 2px) 0;

            .switch-label {
              color: $primary-text-hover;
              font-family: $SodoSans;
              margin-left: calc($grid-1x/2);
            }
          }

          .time-wrapper {
            margin: calc($grid-1x - 2px) 0;

            .time-section {
              width: calc(100% - $grid-4x);

              .timer-picker {
                border-radius: var(--Minimal, 0.5rem);

                .MuiSelect-select {
                  color: $primary-text-hover;
                  font-family: $SodoSans;
                  padding: calc($grid-1x + 3px) calc($grid-2x + 4px)
                    calc($grid-1x + 3px) $grid-1x;
                }

                fieldset {
                  border: 1px solid $grey500;
                }
              }

              .timer-picker-selected {
                border-radius: var(--Minimal, 0.5rem);

                .MuiSelect-select {
                  color: $primary-text-hover;
                  font-family: $SodoSans;
                  padding: calc($grid-1x + 3px) calc($grid-2x + 4px)
                    calc($grid-1x + 3px) $grid-1x;
                }

                fieldset {
                  border: 1px solid $grey500;
                }

                border: 1px solid #00754a;
              }

              .time-divider {
                margin: 0 calc($grid-1x - 5px);
                width: calc($grid-2x + 2px);
                background: url("../styles/assets/svg/arrow-line-sm.svg")
                  no-repeat;
                background-position: right 0;
                background-size: calc($grid-2x + 2px) calc($grid-2x - 3px);
                height: calc($grid-2x - 2px);
              }
            }

            .MuiTextField-root {
              min-width: auto;
            }

            .remove-time {
              color: $primary-text;
              padding: calc($grid-1x/2);

              svg {
                font-size: 1.8rem;
              }
            }
          }

          .add-more-time {
            color: $primary-text;
            padding: calc($grid-1x/2);

            svg {
              font-size: 1.8rem;
              margin: calc($grid-1x - 6px) calc($grid-1x - 5.1px) 0px 0px;
            }
          }
        }
      }

      .comment {
        position: relative;
        margin: $grid-1x 0;
        background-color: $white;
        border: 1px solid $black700;
        @include roundCorner($grid-1x);

        .optional {
          color: $primary-text-hover;
          position: absolute;
          bottom: calc($grid-1x + 2px);
          left: calc($grid-1x + 2px);
        }

        .characters-left {
          color: $primary-text-hover;
          position: absolute;
          bottom: calc($grid-1x + 2px);
          right: calc($grid-1x + 2px);
        }

        .comment-box {
          width: 100%;
          padding-bottom: $grid-4x;
        }

        .comment-box:focus-visible {
          outline: 2px solid #00754a;
        }

        .text-area {
          @include roundCorner($grid-1x);
          padding: $grid-1x;
          background-color: $white;
          border: 0;
          resize: none;
          font-size: 1rem;

          &::placeholder {
            color: $primary-text-hover;
            font-family: $SodoSansLight;
          }

          &:focus-visible {
            outline: 2px solid #00754a;
          }
        }
      }
    }
  }

  .availabilityRequestInfoIcon {
    padding: $grid-1x/2 $grid-1x 0 $grid-1x;
  }

  button.submit-btn {
    min-width: 8rem;
    min-height: 1.8rem;
    display: flex;
    padding: 0rem 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-minimal, 0.5rem);
    border: 0.5px solid var(--color-line-quiet, #bfbfbf);
    background: var(--color-interactive-tertiary, #fff);

    &[disabled] {
      border-radius: var(--radius-minimal, 0.5rem);
      background: var(--color-interactive-primary-disabled, #666);
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-mobile) {
  .drawer {
    .date-wrapper {
      &.half-width {
        width: calc(50% - $grid-3x);
      }
    }

    .dividerStoreHrs {
      width: 102%;
    }

    .idealShiftsDayCardBlock {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      align-self: stretch;
    }

    #setIdealShiftsText {
      color: var(--color-ink-hero, #00754a);
      font-family: "SoDo Sans Semi Bold";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .add-request-section {
      .request-content {
        display: flex;
        padding: $grid-1x calc($grid-1x + 4px);
        flex: 1 0 100%;
        overflow-y: scroll;
        overscroll-behavior-y: contain;

        .day-wrapper {
          .day-item {
            width: calc($grid-4x + 4px);
            justify-content: center;
          }

          .day-action {
            padding: calc($grid-2x - 2px) 0 calc($grid-1x + 4px) $grid-2x;
            width: calc(100% - $grid-8x);

            .time-wrapper {
              .timer-picker {
                width: calc(50% - $grid-1x - 4px);
                border-radius: var(--Minimal, 0.5rem);

                .MuiSelect-select {
                  padding: calc($grid-1x + 3px) calc($grid-4x - 2px)
                    calc($grid-1x + 3px) calc($grid-1x + 1px);
                }
              }

              .timer-picker-selected {
                width: calc(50% - $grid-1x - 4px);
                border-radius: var(--Minimal, 0.5rem);

                .MuiSelect-select {
                  padding: calc($grid-1x + 3px) calc($grid-4x - 2px)
                    calc($grid-1x + 3px) calc($grid-1x + 1px);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-mobile) {
  .drawer {
    .date-wrapper {
      &.active {
        border: 1px solid $green750;

        &::before {
          top: calc($grid-7x + 2px);
        }

        &::after {
          top: calc($grid-8x);
        }
      }

      &.startDateBorder {
        border: 1px solid $green750;
      }
    }

    #setIdealShiftsText {
      color: var(--color-ink-hero, #00754a);
      font-family: "SoDo Sans Semi Bold";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .add-request-section {
      .request-content {
        .day-wrapper {
          .day-action {
            .time-wrapper {
              .time-section {
                width: calc(100% - $grid-4x - 4px);

                .timer-picker {
                  width: calc(50% - $grid-1x - 4px);
                  border-radius: var(--Minimal, 0.5rem);
                }

                .timer-picker-selected {
                  width: calc(50% - $grid-1x - 4px);
                  border-radius: var(--Minimal, 0.5rem);
                }
              }
            }
          }
        }
      }
    }

    button.next-btn,
    button.submit-btn,
    button.confirm-btn {
      display: flex;
      height: 2rem;
      padding: 0rem 0.5rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-minimal, 0.5rem);
      color: var(--color-ink-on-primary-disabled, #fff);
      font-family: "SoDo Sans";
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25rem;

      &[disabled] {
        background: var(--color-interactive-primary-disabled, #666);
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-sm-desktop) {
  .drawer {
    .leftPane {
      height: 83vh;
      border-radius: var(--radius-none, 0rem);
      background: var(--Surface-level-1, #fff);
      width: calc(50 * $grid-1x);
      padding: $grid-4x $grid-1x 0 $grid-2x;
      overflow: scroll;

      .MuiDivider-vertical {
        height: $grid-5x;
        margin: calc($grid-2x - 2px) calc($grid-2x - 2px) calc($grid-2x + 2px);
      }
    }

    .rightPane {
      height: 83vh;
      background-color: $white500;
      width: calc(100% - 50 * $grid-1x);
      padding: $grid-1x 0 $grid-1x $grid-2x;

      .close {
        margin: calc($grid-1x - 2px) $grid-2x $grid-3x;
      }

      .rightPanelItem {
        position: relative;

        .rightPanelHeight {
          height: 81vh;

          .rightPanelItems {
            width: 90%;
            position: relative;
            height: calc(100vh - 21.25 * $grid-1x);

            .section-title {
              font-size: 1.333rem;
              margin-bottom: calc($grid-3x + 6px);
            }
          }
        }

        button.next-btn {
          position: relative;
          bottom: 1%;
          right: 0;

          &.bottom-pref,
          &.bottom-avail {
            position: absolute;
          }
        }

        button.confirm-btn {
          position: absolute;
          bottom: 1%;
          right: 0;
        }

        button.next-btn,
        button.confirm-btn,
        button.submit-btn {
          min-width: 8rem;
          min-height: 1.8rem;
          width: calc(5 * $grid-7x);
          padding: $grid-1x 0;
        }
      }
    }

    .add-request-section {
      .request-content {
        height: 95%;
        width: 85%;
        margin-left: 15%;

        .day-wrapper {
          .day-item {
            width: $grid-7x;
          }

          .day-action {
            width: calc(100% - $grid-9x - $grid-3x);
            padding: $grid-2x;
          }
        }
      }
    }

    .val-msg-container {
      width: 85%;
    }
  }
}

@media only screen and (min-width: $breakpoint-normal-desktop) {
  .simplebar-content-wrapper {
    outline: none;
  }

  .drawer {
    .leftPane {
      height: 83vh;
      border-radius: var(--radius-none, 0rem);
      background: var(--Surface-level-1, #fff);
      width: calc(15 * $grid-5x);
      padding: $grid-4x $grid-3x 0 $grid-4x;
      overflow: scroll;
    }

    .rightPane {
      height: 83vh;
      background-color: $white500;
      width: calc(100% - 15 * $grid-5x);
      padding: $grid-1x 0 $grid-1x $grid-4x;

      .rightPanelItem {
        .rightPanelHeight {
          height: 81vh;

          .rightPanelItems {
            width: calc(80 * $grid-1x);
            height: calc(100vh - 25 * $grid-1x);

            &.avail-drawer {
              width: calc(89.25 * $grid-1x);

              .statement {
                width: 85%;
              }
            }
          }
        }

        .date-wrapper {
          padding: calc($grid-2x + 4px) calc($grid-1x + 4px);

          &.half-width {
            width: calc(50% - $grid-4x);
          }

          svg {
            width: calc($grid-3x + 4px);
            height: calc($grid-3x + 4px);
          }

          &.active {
            border: 1px solid $green750;

            &::before {
              top: calc($grid-10x + $grid-1x + 2px);
              padding: $grid-1x;
            }

            &::after {
              top: calc($grid-10x + $grid-2x + 1px);
              width: calc($grid-2x + 3px);
            }
          }

          &.startDateBorder {
            border: 1px solid $green750;
          }
        }

        button.next-btn,
        button.submit-btn,
        button.confirm-btn {
          min-width: 8rem;
          min-height: 1.8rem;
          width: calc(45.5 * $grid-1x);
          @include roundCorner($grid-4x);
          font-size: 1.5rem;
          padding: calc($grid-1x - 2px) 0;
        }
      }
    }

    .add-request-section {
      .request-content {
        height: 95%;
        width: 90%;
        margin-left: 10%;

        .day-wrapper {
          .day-action {
            .time-wrapper {
              .time-section {
                width: calc(100% - $grid-5x - 5px);

                .timer-picker {
                  border-radius: var(--Minimal, 0.5rem);
                  width: calc(50% - $grid-1x - 64px);
                }

                .timer-picker-selected {
                  border-radius: var(--Minimal, 0.5rem);
                  width: calc(50% - $grid-1x - 64px);
                }

                .time-divider {
                  width: calc(14.25 * $grid-1x);
                  background: url("../styles/assets/svg/arrow-line.svg")
                    no-repeat;
                  background-position: -$grid-3x 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint-large-desktop) {
  .drawer {
    .date-wrapper {
      width: calc($grid-1x * 45);
    }

    .leftPane {
      height: 83vh;
      width: calc(70 * $grid-1x);
      border-radius: var(--radius-none, 0rem);
      background: var(--Surface-level-1, #fff);
      padding: $grid-6x $grid-3x 0 calc($grid-9x - 2px);
      overflow: scroll;
    }

    .rightPane {
      height: 83vh;
      background-color: $white500;
      padding: $grid-1x 0 $grid-1x $grid-6x;

      .rightPanelItem {
        height: 81vh;

        .rightPanelHeight {
          .rightPanelItems {
            width: calc(83 * $grid-1x);

            &.avail-drawer {
              width: calc($grid-1x * 97.625);
            }
          }
        }

        button.next-btn {
          position: absolute;
        }

        button.next-btn,
        button.submit-btn,
        button.confirm-btn {
          min-width: 8rem;
          min-height: 1.8rem;
          width: calc(54.375 * $grid-1x);
          padding: calc($grid-1x + 1px) 0;
        }
      }
    }

    .add-request-section {
      .request-content {
        height: 95%;
        width: 80%;
        margin-left: 15%;
        padding: calc($grid-3x - 2px) calc($grid-3x + 2px);

        .day-wrapper {
          .day-action {
            .time-wrapper {
              .time-section {
                .time-divider {
                  width: calc(17.75 * $grid-1x);
                  background-position: 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.progress {
  margin-right: 0.5rem;
}
